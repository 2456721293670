<template>
  <layout-navigation>
    <section :class="$style.page">
      <header :class="$style.header">
        <date-range-picker
          :value="dateRange"
          :clearable="false"
          @inputDateFrom="dateFromChanged"
          @inputDateTo="dateToChanged"
        />

        <vue-select
          v-model="selectedCategory"
          placeholder="Все категории"
          :options="categoryOptions"
          :reduce="(option) => option.id"
          label="name"
          :searchable="false"
          @input="filterTableData"
        >
          <template #header>
            <span class="nameSpan"> Категория событий</span>
          </template>
        </vue-select>

        <vue-select
          v-model="selectedStatus"
          placeholder="Все события"
          :options="statusOptions"
          :reduce="(option) => option.id"
          label="name"
          :searchable="false"
          @input="filterTableData"
        >
          <template #header>
            <span class="nameSpan"> Статус событий</span>
          </template>
        </vue-select>

        <div :class="$style.container">
          <label>
            <span :class="$style.visuallyHidden">Поиск</span>
            <input
              v-model="searchString"
              :class="$style.input"
              type="text"
              placeholder="Поиск"
              @input="filterTableData"
            >
          </label>
        </div>
      </header>
      <group-table
        :table-data="filterData"
        empty-result-string="Нет данных за выбранный период"
        :columns="columns"
        @changeSort="changeSort"
        @filterClick="filterClick"
        @update="fetch"
      />
    </section>
  </layout-navigation>
</template>

<script>
import dayjs from 'dayjs';
import VueSelect from 'vue-select';
import LayoutNavigation from '@/layouts/navigation.vue';
import DateRangePicker from '@/components/common/filter/separated-date-picker.vue';
import { getNotificationsHistory } from '@/api/methods/notifications';
import GroupTable from '@/components/common/table/tableWithGrouping.vue';
import 'vue-select/src/scss/vue-select.scss';
import OpenIndicator from '@/components/common/header/vue-select-custom/open-indicator.vue';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

VueSelect.props.components.default = () => ({ OpenIndicator });

export default {
  components: {
    LayoutNavigation,
    DateRangePicker,
    GroupTable,
    VueSelect,
  },
  data: () => ({
    updateNeeded: false,
    searchString: '',
    selectedCategory: undefined,
    selectedStatus: undefined,
    tableData: [],
    filterData: [],
    statusOptions: [
      {
        id: 1,
        name: 'Только подтвержденные',
      },
      {
        id: 0,
        name: 'Не подтвержденные',
      },
    ],
    categoryOptions: [
      {
        id: 1,
        name: 'Набираемый текст',
      },
      {
        id: 2,
        name: 'Программы',
      },
      {
        id: 3,
        name: 'Файловая система',
      },
      {
        id: 4,
        name: 'Буфер обмена',
      },
      {
        id: 6,
        name: 'Установка/удаление программ',
      },
      {
        id: 7,
        name: 'Посещенные сайты',
      },
      {
        id: 10,
        name: 'Теневая копия',
      },
      {
        id: 11,
        name: 'Подкл/откл носителей информации',
      },
      {
        id: 12,
        name: 'Активное оповещение',
      },
      {
        id: 13,
        name: 'Почта',
      },
      {
        id: 17,
        name: 'Сеть:Файлы',
      },
      {
        id: 18,
        name: 'Мессенджеры',
      },
      {
        id: 20,
        name: 'Социальные сети',
      },
      {
        id: 21,
        name: 'Web почта',
      },
      {
        id: 22,
        name: 'Выгружаемые файлы',
      },
      {
        id: 23,
        name: 'Принтер',
      },
      {
        id: 24,
        name: 'Мессенджеры:Файлы',
      },
      {
        id: 26,
        name: 'Изменения оборудования',
      },
    ],
    columns: [
      {
        name: 'Агент',
        width: '8%',
        widthNum: 8,
        field: 'title',
        sort: 1,
        index: 0,
        filter: [],
        selected: [],
        showFilter: false,
      },
      {
        name: 'Время события',
        width: '8%',
        widthNum: 8,
        field: 'eventTime',
        sort: 0,
        index: 1,
        filter: [],
        selected: [],
        showFilter: false,
      },
      {
        name: 'Причина',
        width: '15%',
        widthNum: 15,
        field: 'reasonString',
        sort: 0,
        index: 2,
        filter: [],
        selected: [],
        showFilter: false,
      },
      {
        name: 'Категория',
        width: '8%',
        widthNum: 8,
        field: 'categoryName',
        sort: 0,
        index: 3,
        filter: [],
        selected: [],
        showFilter: false,
      },
      {
        name: 'Контролер',
        width: '8%',
        widthNum: 8,
        field: 'checkUser',
        sort: 0,
        index: 4,
        filter: [],
        selected: [],
        showFilter: false,
      },
      {
        name: 'Время подтверждения',
        width: '10%',
        widthNum: 10,
        field: 'checkTime',
        sort: 0,
        index: 5,
        filter: [],
        selected: [],
        showFilter: false,
      },
      {
        name: 'Содержимое',
        width: '20%',
        widthNum: 20,
        field: 'descriptionOutput',
        sort: 0,
        index: 6,
        filter: [],
        selected: [],
        showFilter: false,
      },
      {
        name: 'Ключевые слова',
        width: '8%',
        widthNum: 8,
        field: 'keywords',
        sort: 0,
        index: 7,
        filter: [],
        selected: [],
        showFilter: false,
      },
    ],
  }),
  computed: {
    dateFrom() {
      return new Date(this.$route.query.dateFrom);
    },
    dateTo() {
      return new Date(this.$route.query.dateTo);
    },
    dateRange() {
      if (!this.$route.query.dateFrom || !this.$route.query.dateTo) {
        this.whenUpdated();
      }

      return [
        new Date(this.$route.query.dateFrom),
        new Date(this.$route.query.dateTo),
      ];
    },
  },
  watch: {
    async updateNeeded(value) {
      if (value) {
        await this.fetch();
        this.updateNeeded = false;
      }
    },
  },
  async created() {
    this.whenUpdated();
  },
  methods: {
    whenUpdated() {
      this.$store.commit('navigation/setNewNotifications', false);

      const queryMutations = {};
      const defaultDateQuery = dayjs().format('YYYY-MM-DD');

      let threeDaysAgo = new Date();
      threeDaysAgo.setDate(threeDaysAgo.getDate() - 2);
      threeDaysAgo = dayjs(threeDaysAgo).format('YYYY-MM-DD');

      if (!this.$route.query.dateFrom) {
        queryMutations.dateFrom = threeDaysAgo;
      }

      if (!this.$route.query.dateTo) {
        queryMutations.dateTo = defaultDateQuery;
      }

      if (Object.keys(queryMutations).length) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            ...queryMutations,
          },
        });
      }

      this.updateNeeded = true;
    },
    filterClick(column) {
      const value = column.showFilter;

      column.showFilter = !value;

      if (column.showFilter) {
        window.addEventListener('click', (e) => {
          if (
            !document
              .getElementById(`columnFilter${column.field}`)
              .contains(e.target)
            && !document
              .getElementById(`filterIcon${column.field}`)
              .contains(e.target)
          ) {
            column.showFilter = false;
            window.removeEventListener('click', e);
          }
        });
      }
    },
    changeSort(column) {
      const { sort } = column;
      this.columns.forEach((col) => {
        col.sort = 0;
      });

      if (sort === 1) {
        column.sort = 2;
      } else column.sort = 1;
    },
    filterTableData() {
      let result = this.tableData;
      if (this.selectedCategory) {
        result = result.filter(
          (item) => item.categoryId === this.selectedCategory,
        );
      }
      if (this.selectedStatus === 1) {
        result = result.filter((item) => item.checkUser !== null);
      }
      if (this.selectedStatus === 0) {
        result = result.filter((item) => item.checkUser === null);
      }

      if (this.searchString.length > 0) {
        result = result.filter(
          (item) => item.descriptionOutput.indexOf(this.searchString) !== -1
            || item.keywords.indexOf(this.searchString) !== -1,
        );
      }

      this.filterData = result;
      this.assembleColumnFilter();
    },
    assembleColumnFilter() {
      this.columns.forEach((column) => {
        const filter = [];

        this.filterData.forEach((item) => {
          if (item[column.field] && filter.indexOf(item[column.field]) === -1) {
            filter.push(item[column.field]);
          }
        });
        column.filter = filter;
      });
    },
    async fetch() {
      this.dateFrom.setHours(0);
      this.dateFrom.setMinutes(0);
      this.dateFrom.setSeconds(0);

      this.dateTo.setHours(23);
      this.dateTo.setMinutes(59);
      this.dateTo.setSeconds(59);

      const result = await getNotificationsHistory(this.dateFrom, this.dateTo);
      if (result !== 'error') {
        result.forEach((item) => {
          if (item.descriptionString.length === 0) {
            if (item.description) item.descriptionOutput = item.description;
            else item.descriptionOutput = '';
          } else item.descriptionOutput = item.descriptionString;
        });
        this.tableData = result;
        // this.filterData = result;
        this.filterTableData();
        this.assembleColumnFilter();
      }
    },
    async dateFromChanged(val) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          dateFrom: dayjs(val).format('YYYY-MM-DD'),
        },
      });
      await this.fetch();
    },
    async dateToChanged(val) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          dateTo: dayjs(val).format('YYYY-MM-DD'),
        },
      });
      await this.fetch();
    },
  },
};
</script>

<style lang="scss" module>
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.page {
  background: $white;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.header {
  box-shadow: $shadow;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  padding: 20px 30px;
}

.control {
  display: flex;
  align-items: center;
}

.visuallyHidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

.input {
  border: 1px solid $borderColor;
  color: $black;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 12px;
  height: 50px;
  width: 100%;

  &::placeholder {
    color: dakren($dark-gray, 3%);
  }
}

.container {
  width: 25%;
}
</style>
<style lang="scss">
.v-select {
  width: 20%;

  & * {
    font-style: normal;
    font-size: 14px;
  }

  .vs__dropdown-toggle {
    height: 50px;
    border: 1px solid #dfdfdf;
    border-radius: 0px;
    // width: 300px;
  }

  &.vs--single.vs--searching span.vs__selected {
    display: none !important;
  }

  .vs__selected-options {
    padding: 13px 0 0 9px;
    min-width: 0;

    .vs__selected {
      display: block;
      height: 30px;
      width: 244px;
      line-height: 30px;
      font-family: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: $black;
    }

    .vs__search {
      height: 30px;
      width: 244px;
      line-height: 30px;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .nameSpan {
    position: absolute;
    top: -2px;
    left: 16px;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 30px;
    color: #7e7e7e;
  }

  ul {
    // width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: auto;
  }

  ul>li {
    //  width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>
