<template>
  <div>
    <date-picker
      v-model="value[0]"
      :class="$style.datePicker"
      :clearable="clearable"
      :disabled-date="disabledBefore"
      @input="(value) => $emit('inputDateFrom', value)"
    >
      <template v-slot:input>
        <div :class="$style.filter">
          <div :class="$style.dateWrapper">
            <p :class="$style.dateText">
              Дата от
            </p>
            <p
              v-if="value[0]"
              :class="$style.date"
            >
              {{ value[0] | date }}
            </p>
          </div>
        </div>
      </template>
    </date-picker>
    <date-picker
      v-model="value[1]"
      :class="$style.datePicker"
      :clearable="clearable"
      :disabled-date="disabledAfter"
      @input="(value) => $emit('inputDateTo', value)"
    >
      <template v-slot:input>
        <div :class="$style.filter">
          <div :class="$style.dateWrapper">
            <p :class="$style.dateText">
              Дата до
            </p>
            <p
              v-if="value[1]"
              :class="$style.date"
            >
              {{ value[1] | date }}
            </p>
          </div>
        </div>
      </template>
    </date-picker>
  </div>
</template>

<script>
/* eslint-disable no-shadow */
import DatePicker from 'vue2-datepicker';
import { date } from '@/helpers/filters';

export default {
  filters: {
    date,
  },
  components: {
    DatePicker,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    disable: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    disabledBefore(date) {
      if (this.disable) {
        const result = new Date(this.value[1]);
        result.setDate(result.getDate() - 14);

        return date < result;
      }
      return null;
    },
    disabledAfter(date) {
      if (this.disable) {
        const result = new Date(this.value[0]);
        result.setDate(result.getDate() + 13);

        return date > result;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" module>
.datepicker {
  cursor: pointer;
}

.datepickerPopup td {
  vertical-align: middle;
}

.filter {
  border: 1px solid #dfdfdf;
  display: flex;
  height: 50px;
}

.dateWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 6px 15px;
  min-width: 107px;
}

.dateText {
  color: #7e7e7e;
  font-size: 12px;
  margin-bottom: 8px;
}

.date {
  font-size: 14px;
}
</style>
